import React, { useContext } from "react"
import { shape, string, arrayOf, bool } from "prop-types"
import { Location } from "@reach/router"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import { rgba } from "polished"
import { Flex, Box } from "rebass"
import ProductsMenu from "../products-menu"
import Language from "../language"
import MainMenuLink from "./main-menu-link"
import UIContext from "./ui-context"

const propTypes = {
  intl: shape().isRequired,
  slugs: shape({
    en: string,
    fi: string,
  }).isRequired,
  currentPath: string.isRequired,
  mainNavItems: arrayOf(shape()).isRequired,
  footerNavItems: arrayOf(shape()).isRequired,
  open: bool.isRequired,
}

const StyledMenu = styled.div`
  position: fixed;
  overflow: scroll;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  background-color: ${props => props.theme.colours.white};
  @media ${props => props.theme.breakpoint.navCollapse} {
    overflow: visible;
    position: static;
    transform: none;
    height: auto;
    top: auto;
    right: auto;
    bottom: auto;
  }
`
const MenuHeader = styled.div`
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-top: 50px;
  padding-left: ${({ theme }) => `${theme.gridGutter / 2}px`};
  padding-right: ${({ theme }) => `${theme.gridGutter / 2}px`};
  /* @media ${({ theme }) => theme.breakpoint.tablet} {
    padding-left: ${({ theme }) => `${theme.gridGutter}px`};
    padding-right: ${({ theme }) => `${theme.gridGutter}px`};
  } */
  @media ${({ theme }) => theme.breakpoint.laptop} {
    padding-left: ${({ theme }) => `${theme.siteBorder}px`};
    padding-right: ${({ theme }) => `${theme.siteBorder}px`};
  }
  @media ${({ theme }) => theme.breakpoint.desktop} {
    padding-left: ${({ theme }) => `${theme.gridGutter * 2}px`};
    padding-right: ${({ theme }) => `${theme.gridGutter * 2}px`};
  }
  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    /* padding-bottom: 1vw; */
    /* height: 4.5vw; */
    position: fixed;
    padding-top: 0;
    padding-bottom: 0.8em;
    background-color: red;
    background: ${({ theme }) => theme.currentColours.bg};
    background: linear-gradient(
      180deg,
      ${({ theme }) => theme.currentColours.bg},
      ${({ theme }) => theme.currentColours.bg} 87%,
      ${({ theme }) => rgba(theme.currentColours.bg, 0)} 100%
    );
  }
`
const MenuFooter = styled(MenuHeader)`
  top: auto;
  bottom: 0;
  padding-bottom: 50px;
  padding-top: 0;
  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    background: ${({ theme }) => theme.currentColours.bg};
    background: linear-gradient(
      180deg,
      ${({ theme }) => rgba(theme.currentColours.bg, 0)},
      ${({ theme }) => theme.currentColours.bg} 30%,
      ${({ theme }) => theme.currentColours.bg} 100%
    );
    padding-top: 10px;
    padding-bottom: 0;
  }
`
const StyledFlex = styled(Flex)`
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
`
const StyledBox = styled(Box)`
  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    flex: 1;
    text-align: center;
    &:first-of-type {
      flex: 1;
      text-align: left;
    }
    &:last-child {
      flex: 1;
      text-align: right;
    }
  }
`
const ProductsMenuDesktop = styled(ProductsMenu)`
  display: none;
  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    display: block;
  }
`
const ProductsMenuMobile = styled(ProductsMenu)`
  display: block;
  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    display: none;
  }
`

const Menu = ({
  intl,
  slugs,
  mainNavItems,
  footerNavItems,
  currentPath,
  open,
}) => {
  const isHidden = !!open
  const { locale } = intl
  const { headerResizeListener, footerResizeListener } = useContext(UIContext)
  return (
    <StyledMenu open={open} aria-hidden={!isHidden}>
      <MenuHeader>
        {headerResizeListener}
        <StyledFlex>
          {mainNavItems &&
            mainNavItems.map(item => (
              <StyledBox key={item.slug} width={[1, 1, 1, 1, 1 / 3]}>
                <MainMenuLink
                  slug={`/${item.slug}`}
                  active={
                    currentPath &&
                    currentPath.includes(`${locale}/${item.slug}`)
                      ? "true"
                      : "false"
                  }
                >
                  {item.title}
                </MainMenuLink>
                {item.slug.includes("tuotteet") ||
                item.slug.includes("products") ? (
                  <ProductsMenuMobile type="mobile" currentPath={currentPath} />
                ) : null}
              </StyledBox>
            ))}
        </StyledFlex>
        <Location>
          {({ location }) => {
            if (
              location.pathname.includes("/product/") ||
              // location.pathname.includes("/tuotteet") ||
              location.pathname.includes("/catalogue") ||
              location.pathname.includes("/tuoteluettelo")
            ) {
              return (
                <ProductsMenuDesktop
                  type="desktop"
                  location={location}
                  currentPath={currentPath}
                />
              )
            }
            return null
          }}
        </Location>
      </MenuHeader>
      <MenuFooter>
        {footerResizeListener}
        <StyledFlex>
          {footerNavItems &&
            footerNavItems.map(item => (
              <StyledBox key={item.slug} width={[1, 1, 1, 1, 1 / 3]}>
                <MainMenuLink
                  type="footer"
                  slug={`/${item.slug}`}
                  active={
                    currentPath &&
                    currentPath.includes(`${locale}/${item.slug}`)
                      ? "true"
                      : "false"
                  }
                >
                  {item.title}
                </MainMenuLink>
              </StyledBox>
            ))}
          <StyledBox>
            <Language slugs={slugs} />
          </StyledBox>
        </StyledFlex>
      </MenuFooter>
    </StyledMenu>
  )
}

Menu.propTypes = propTypes

export default injectIntl(Menu)
