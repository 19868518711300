import React from "react"
import { bool, func } from "prop-types"
import styled from "@emotion/styled"

const propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
}

const StyledBurger = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.gridGutter / 2}px;
  right: ${({ theme }) => theme.gridGutter / 2}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40px;
  height: 21px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 101;

  &:focus {
    outline: none;
  }

  span {
    width: 40px;
    height: 3px;
    background: ${({ theme, open }) =>
      open ? theme.colours.black : theme.currentColours.fg};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }

  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    display: none;
  }
`

const Burger = ({ open, setOpen }) => {
  const isExpanded = !!open
  return (
    <StyledBurger
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      open={open}
      onClick={() => setOpen(!open)}
    >
      <span />
      <span />
      <span />
    </StyledBurger>
  )
}

Burger.propTypes = propTypes

export default Burger
