/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import styled from "@emotion/styled"
import { rgba } from "polished"

const StyledLegend = styled.legend`
  font-size: 14px;
  color: ${({ theme }) => theme.currentColours.fg};
`

const Legend = ({ children, ...props }) => (
  <StyledLegend {...props}>{children}</StyledLegend>
)

export default Legend
