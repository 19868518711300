/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import styled from "@emotion/styled"
import { func } from "prop-types"
import { Textarea } from "@rebass/forms"

const propTypes = {
  forwardRef: func.isRequired,
}

const StyledTextArea = styled(Textarea)`
  font-size: 1rem;
  border-radius: 16px;
  border-width: 1px !important;
  border-color: ${({ theme }) => theme.currentColours.fg}!important;
  height: 20vw;
  &::placeholder {
    color: ${({ theme }) => theme.currentColours.fg}!important;
    opacity: 1;
  }
  &:focus::placeholder {
    color: transparent !important;
  }
`

const TextArea = ({ forwardRef, ...props }) => (
  <StyledTextArea ref={forwardRef} {...props} />
)

TextArea.propTypes = propTypes

export default TextArea
