import styled from "@emotion/styled"

const Heading2 = styled.h2`
  font-family: ${({ theme }) => theme.typography.standardFamily};
  font-weight: 600;
  font-size: 160% !important;
  line-height: 1.2;
  letter-spacing: 0.1em;
  margin-bottom: 0.5em;
  @media ${({ theme }) => theme.breakpoint.desktop} {
    font-size: 155% !important;
  }
`

export default Heading2
