import AdieuLightOtf from "./Adieu-Light.otf"
import AdieuRegularOtf from "./Adieu-Regular.otf"
import AdieuBoldOtf from "./Adieu-Bold.otf"
import AdieuBlackOtf from "./Adieu-Black.otf"
import AdieuLightWoff from "./Adieu-Light.woff"
import AdieuRegularWoff from "./Adieu-Regular.woff"
import AdieuBoldWoff from "./Adieu-Bold.woff"
import AdieuBlackWoff from "./Adieu-Black.woff"

import ThoreauEot from "./ThoreauBeta1.9-DisplayRegular.eot"
import ThoreauWoff from "./ThoreauBeta1.9-DisplayRegular.woff"
import ThoreauWoff2 from "./ThoreauBeta1.9-DisplayRegular.woff2"

export default {
  AdieuLightOtf,
  AdieuRegularOtf,
  AdieuBoldOtf,
  AdieuBlackOtf,
  AdieuLightWoff,
  AdieuRegularWoff,
  AdieuBoldWoff,
  AdieuBlackWoff,
  ThoreauEot,
  ThoreauWoff,
  ThoreauWoff2,
}
