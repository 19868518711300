import React from "react"
import { shape, string } from "prop-types"
import { injectIntl, Link } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import MenuCategoryLink from "../atoms/menu-category-link"

const propTypes = {
  intl: shape().isRequired,
  item: shape({
    slug: string,
    title: string,
  }).isRequired,
  currentPath: string.isRequired,
  open: string,
}

const defaultProps = {
  open: "",
}

const MenuLink = styled(MenuCategoryLink)`
  border-left: ${({ active, theme }) =>
    active ? `1px solid ${theme.colours.black}` : "none"};
  /* border-left: 1px solid ${({ theme }) => theme.currentColours.fg}; */
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;
  line-height: 3;
  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    border-left: ${({ active, theme }) =>
      active ? `1px solid ${theme.currentColours.fg}` : "none"};
    line-height: 1;
  }
`
const SubMenu = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  padding: 1em 1em 1em;
  border-left: 1px solid ${({ theme }) => theme.currentColours.fg};
  z-index: 10;
  flex-wrap: wrap;
  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    display: ${({ open }) => (open ? "block" : "none")};
    position: absolute;
    background-color: ${({ theme }) => theme.currentColours.bg};
    overflow-y: auto;
    max-height: 80vh;
  }
`
const SubMenuLink = styled(Link)`
  font-family: ${({ theme }) => theme.typography.standardFamily};
  font-weight: ${({ active }) => (active === "true" ? "600" : "300")};
  font-size: 0.675rem;
  display: block;
  line-height: 1.0625rem;
  padding: 0.526em 0 0;
  letter-spacing: 0.1em;
  text-transform: none;
  text-decoration: none;
  white-space: nowrap;
  color: ${({ theme }) => theme.currentColours.fg};
  flex: 0 0 50%;
  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    flex: 0 0 100%;
  }
`
const MenuLinkWrapper = styled.div`
  flex: 0 0 100%;
  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    flex: 0 1 auto;
    /* background-color: ${({ theme }) => theme.currentColours.bg}; */
    &:last-of-type {
      ${MenuLink} {
        border-right: 1px solid ${({ theme }) => theme.currentColours.fg};
        padding-right: 10px;
        padding-left: 0;
        border-left: none;
      }
      ${SubMenu} {
        left: auto;
        right: 0;
        text-align: right;
        border-right: 1px solid ${({ theme }) => theme.currentColours.fg};
        border-left: none;
      }
    }
  }
  position: relative;
`
const MenuItem = ({ intl, item, currentPath, open, setOpen, forwardRef }) => {
  const { title, displayName, slug, subCategory } = item
  let subMenuOpen = false
  if (item.slug === open) {
    subMenuOpen = true
  } else {
    subMenuOpen = false
  }
  return (
    <MenuLinkWrapper ref={item.slug === open ? forwardRef : null}>
      <MenuLink
        active={currentPath && currentPath.includes(slug) ? "true" : "false"}
        open={open}
        setOpen={setOpen}
        slug={item.slug}
      >
        {displayName || title}
      </MenuLink>
      {subCategory && (
        <SubMenu open={subMenuOpen}>
          {subCategory.map(i => {
            return (
              <SubMenuLink
                key={i.slug}
                to={`${intl.formatMessage({ id: "cat_path" })}/${slug}/${
                  i.slug
                }`}
                active={
                  currentPath && currentPath.includes(i.slug) ? "true" : "false"
                }
              >
                {i.title}
              </SubMenuLink>
            )
          })}
        </SubMenu>
      )}
    </MenuLinkWrapper>
  )
}

MenuItem.propTypes = propTypes

MenuItem.defaultProps = defaultProps

export default injectIntl(MenuItem)
