import React, { useState, useEffect } from "react"
import { shape, node, string, bool } from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import { ThemeProvider } from "emotion-theming"
import useResizeAware from "react-resize-aware"
import GlobalStyle from "../config/global-style"
import theme, { getColoursByTheme } from "../config/theme"
import Header from "./header"
import Modal from "./molecules/modal"
import ContactForm from "./molecules/contact-form"
import MaintenanceForm from "./molecules/maintenance-form"
import ModalContext from "./molecules/modal-context"
import UIContext from "./molecules/ui-context"

const propTypes = {
  colourTheme: string,
  children: node.isRequired,
  slugs: shape({
    en: string,
    fi: string,
  }).isRequired,
  currentPath: string.isRequired,
  hasProductsHeader: bool,
}

const defaultProps = {
  colourTheme: "white",
  hasProductsHeader: false,
}

const Main = styled.div`
  padding-top: 50px;
  @media ${theme.breakpoint.navCollapse} {
    padding-top: ${({ hasProductsHeader }) =>
      hasProductsHeader ? "8.2vw" : "3.8vw"};
    padding-top: ${({ padding }) => padding}px!important;
  }
`

const Layout = ({
  colourTheme,
  children,
  slugs,
  currentPath,
  hasProductsHeader,
}) => {
  // const [modalOpen, setModalOpen] = useToggle(false)
  const [modalOpen, setModalOpen] = useState("")
  const [mainPadding, setMainPadding] = useState("3.8vw")
  const [headerResizeListener, headerSizes] = useResizeAware()
  const [footerResizeListener, footerSizes] = useResizeAware()
  useEffect(() => {
    const topPadding = headerSizes.height
    setMainPadding(topPadding)
  }, [headerSizes, footerSizes])

  // Overrides the colour theme related styles
  Object.assign(theme.currentColours, getColoursByTheme(colourTheme))
  return (
    <ModalContext.Provider value={setModalOpen}>
      <UIContext.Provider
        value={{
          headerResizeListener,
          headerSizes,
          footerResizeListener,
          footerSizes,
        }}
      >
        <ThemeProvider theme={theme}>
          <GlobalStyle theme={theme} />
          <Header slugs={slugs} theme={theme} currentPath={currentPath} />
          <Main
            itemType="http://schema.org/Article"
            padding={mainPadding}
            hasProductsHeader={hasProductsHeader}
          >
            {children}
          </Main>
          {/* <footer>© {new Date().getFullYear()}</footer> */}
          {modalOpen && (
            <Modal
              itemType="http://schema.org/WebPage"
              itemScope
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
            >
              {modalOpen.includes("#maintenance") && <MaintenanceForm />}
              {modalOpen.includes("#contact_remote") && (
                <ContactForm isRemoteSelected />
              )}
              {modalOpen === "#contact" && <ContactForm />}
            </Modal>
          )}
        </ThemeProvider>
      </UIContext.Provider>
    </ModalContext.Provider>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

export default injectIntl(Layout)
