/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { Label, Checkbox as CheckboxBase } from "@rebass/forms"
import styled from "@emotion/styled"
import { any, func, string, shape, oneOfType } from "prop-types"

const propTypes = {
  text: string.isRequired,
  forwardRef: oneOfType([func, shape({ current: any })]),
}

const StyledLabel = styled(Label)`
  font-size: 1rem;
  cursor: pointer;
`

const Checkbox = ({ forwardRef, text, ...props }) => (
  <StyledLabel>
    <CheckboxBase ref={forwardRef} {...props} />
    {text}
  </StyledLabel>
)

const defaultProps = {
  forwardRef: () => {},
}

Checkbox.defaultProps = defaultProps
Checkbox.propTypes = propTypes

export default Checkbox
