import React, { useState } from "react"
import { shape } from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"
import { navigate } from "gatsby-link"
import { useForm } from "react-hook-form"
import styled from "@emotion/styled"
import { Flex, Text, Box } from "rebass"
import themeFile from "../../config/theme"
import Heading2 from "../atoms/heading-2"
import InputText from "../atoms/input-text"
import TextArea from "../atoms/text-area"
import FormButton from "../atoms/form-button"
import Fieldset from "../atoms/fieldset"
import Legend from "../atoms/legend"
import Checkbox from "../atoms/checkbox"
import ErrorMessage from "../atoms/form-error-message"

const propTypes = {
  intl: shape().isRequired,
}

const ContactFormTitle = styled(Heading2)``

const encode = (data) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&")
}

const MaintenanceForm = ({ intl }) => {
  const { locale } = intl

  const [state, setState] = useState({
    language: locale,
  })

  const { register, handleSubmit, errors } = useForm()

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value
    setState({ ...state, [e.target.name]: value })
  }

  const onSubmit = (data, e) => {
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error))
  }

  return (
    <div>
      <ContactFormTitle>
        {intl.formatMessage({ id: "contact.maintenance" })}
      </ContactFormTitle>
      <form
        name="maintenance_form"
        method="post"
        action={`/${locale}/thanks`}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="maintenance_form" />
        <input type="hidden" name="language" readOnly />
        <p hidden>
          <input name="bot-field" onChange={handleChange} />
        </p>
        <Flex mx={themeFile.flexGutter} mb={3} flexWrap="wrap">
          <Box width={[1, 1, 1 / 2]} mb={3} px={themeFile.boxGutter}>
            <InputText
              placeholder={`${intl.formatMessage({ id: "contact.company" })}*:`}
              type="text"
              name="company"
              aria-label={intl.formatMessage({ id: "contact.company" })}
              onChange={handleChange}
              forwardRef={register({ required: true })}
            />
            {errors.company && (
              <ErrorMessage
                message={intl.formatMessage({ id: "contact.error.company" })}
              />
            )}
          </Box>
          <Box width={[1, 1, 1 / 2]} mb={3} px={themeFile.boxGutter}>
            <InputText
              placeholder={`${intl.formatMessage({
                id: "contact.business_id",
              })}*:`}
              type="text"
              name="business-id"
              aria-label={intl.formatMessage({ id: "contact.business_id" })}
              onChange={handleChange}
              forwardRef={register({ required: true })}
            />
            {errors["business-id"] && (
              <ErrorMessage
                message={intl.formatMessage({
                  id: "contact.error.business_id",
                })}
              />
            )}
          </Box>
          <Box mb={3} width={[1]} px={themeFile.boxGutter}>
            <InputText
              placeholder={`${intl.formatMessage({ id: "contact.email" })}*:`}
              type="email"
              name="email"
              aria-label={intl.formatMessage({ id: "contact.email" })}
              onChange={handleChange}
              forwardRef={register({ required: true })}
            />
            {errors.email && (
              <ErrorMessage
                message={intl.formatMessage({ id: "contact.error.email" })}
              />
            )}
          </Box>
        </Flex>
        <Fieldset>
          <Legend>{`${intl.formatMessage({
            id: "contact.billing_address",
          })}`}</Legend>
          <Flex mx={themeFile.flexGutter} flexWrap="wrap">
            <Box width={[1]} px={themeFile.boxGutter} mb={4}>
              <InputText
                placeholder={`${intl.formatMessage({
                  id: "contact.address",
                })}*:`}
                type="text"
                name="billing-address"
                aria-label={intl.formatMessage({ id: "contact.address" })}
                onChange={handleChange}
                forwardRef={register({ required: true })}
              />
              {errors["billing-address"] && (
                <ErrorMessage
                  message={intl.formatMessage({ id: "contact.error.address" })}
                />
              )}
            </Box>
            <Box width={[1, 1, 1 / 2]} px={themeFile.boxGutter} mb={3}>
              <InputText
                placeholder={`${intl.formatMessage({ id: "contact.zip" })}*:`}
                type="text"
                name="billing-zip"
                aria-label={intl.formatMessage({ id: "contact.zip" })}
                onChange={handleChange}
                forwardRef={register({ required: true })}
              />
              {errors["billing-zip"] && (
                <ErrorMessage
                  message={intl.formatMessage({ id: "contact.error.zip" })}
                />
              )}
            </Box>
            <Box width={[1, 1, 1 / 2]} px={themeFile.boxGutter} mb={3}>
              <InputText
                placeholder={`${intl.formatMessage({ id: "contact.city" })}*:`}
                type="text"
                name="billing-city"
                aria-label={intl.formatMessage({ id: "contact.city" })}
                onChange={handleChange}
                forwardRef={register({ required: true })}
              />
              {errors["billing-city"] && (
                <ErrorMessage
                  message={intl.formatMessage({ id: "contact.error.city" })}
                />
              )}
            </Box>
          </Flex>
        </Fieldset>
        <Fieldset>
          <Legend>{`${intl.formatMessage({
            id: "contact.maintenance_location",
          })}`}</Legend>
          <Flex mx={themeFile.flexGutter} flexWrap="wrap">
            <Box width={[1]} px={themeFile.boxGutter} mb={4}>
              <InputText
                placeholder={`${intl.formatMessage({
                  id: "contact.maintenance_location_name",
                })}*:`}
                type="text"
                name="maintenance-location-name"
                aria-label={intl.formatMessage({
                  id: "contact.maintenance_location_name",
                })}
                onChange={handleChange}
                forwardRef={register({ required: true })}
              />
              {errors["maintenance-location-name"] && (
                <ErrorMessage
                  message={intl.formatMessage({
                    id: "contact.error.maintenance_location_name",
                  })}
                />
              )}
            </Box>
            <Box width={[1]} px={themeFile.boxGutter} mb={4}>
              <InputText
                placeholder={`${intl.formatMessage({
                  id: "contact.address",
                })}*:`}
                type="text"
                name="maintenance-location-address"
                aria-label={intl.formatMessage({ id: "contact.address" })}
                onChange={handleChange}
                forwardRef={register({ required: true })}
              />
              {errors["maintenance-location-address"] && (
                <ErrorMessage
                  message={intl.formatMessage({ id: "contact.error.address" })}
                />
              )}
            </Box>
            <Box width={[1, 1, 1 / 2]} px={themeFile.boxGutter} mb={4}>
              <InputText
                placeholder={`${intl.formatMessage({ id: "contact.zip" })}*:`}
                type="text"
                name="maintenance-location-zip"
                aria-label={intl.formatMessage({ id: "contact.zip" })}
                onChange={handleChange}
                forwardRef={register({ required: true })}
              />
              {errors["maintenance-location-zip"] && (
                <ErrorMessage
                  message={intl.formatMessage({ id: "contact.error.zip" })}
                />
              )}
            </Box>
            <Box width={[1, 1, 1 / 2]} px={themeFile.boxGutter} mb={4}>
              <InputText
                placeholder={`${intl.formatMessage({ id: "contact.city" })}*:`}
                type="text"
                name="maintenance-location-city"
                aria-label={intl.formatMessage({ id: "contact.city" })}
                onChange={handleChange}
                forwardRef={register({ required: true })}
              />
              {errors["maintenance-location-city"] && (
                <ErrorMessage
                  message={intl.formatMessage({ id: "contact.error.city" })}
                />
              )}
            </Box>
            <Box width={[1, 1, 1 / 2]} px={themeFile.boxGutter} mb={2}>
              <InputText
                placeholder={`${intl.formatMessage({ id: "contact.hours" })}*:`}
                type="text"
                name="maintenance-location-hours"
                aria-label={intl.formatMessage({ id: "contact.hours" })}
                onChange={handleChange}
                forwardRef={register({ required: true })}
              />
              {errors["maintenance-location-hours"] && (
                <ErrorMessage
                  message={intl.formatMessage({ id: "contact.error.hours" })}
                />
              )}
            </Box>
            <Box width={[1, 1, 1 / 2]} px={themeFile.boxGutter} mb={2}>
              <InputText
                placeholder={`${intl.formatMessage({ id: "contact.phone" })}*:`}
                type="text"
                name="maintenance-location-phone"
                aria-label={intl.formatMessage({ id: "contact.phone" })}
                onChange={handleChange}
                forwardRef={register({ required: true })}
              />
              {errors["maintenance-location-phone"] && (
                <ErrorMessage
                  message={intl.formatMessage({ id: "contact.error.phone" })}
                />
              )}
            </Box>
          </Flex>
        </Fieldset>
        <Fieldset>
          <Legend>{`${intl.formatMessage({
            id: "contact.maintenance_target",
          })}`}</Legend>
          <Flex mx={themeFile.flexGutter} flexWrap="wrap">
            <Box width={[1]} px={themeFile.boxGutter} mb={3}>
              <InputText
                placeholder={`${intl.formatMessage({
                  id: "contact.brand_and_model",
                })}*:`}
                type="text"
                name="maintenance-target-brand-and-model"
                aria-label={intl.formatMessage({
                  id: "contact.brand_and_model",
                })}
                onChange={handleChange}
                forwardRef={register({ required: true })}
              />
              {errors["maintenance-target-brand-and-model"] && (
                <ErrorMessage
                  message={intl.formatMessage({
                    id: "contact.error.brand_and_model",
                  })}
                />
              )}
            </Box>
            <Box width={[1, 1, 1 / 2]} px={themeFile.boxGutter} mb={3}>
              <InputText
                placeholder={`${intl.formatMessage({ id: "contact.pnc" })}:`}
                type="text"
                name="maintenance-target-pnc"
                aria-label={intl.formatMessage({ id: "contact.pnc" })}
                onChange={handleChange}
                forwardRef={register}
              />
            </Box>
            <Box width={[1, 1, 1 / 2]} px={themeFile.boxGutter} mb={3}>
              <InputText
                placeholder={`${intl.formatMessage({ id: "contact.ser" })}:`}
                type="text"
                name="maintenance-target-ser"
                aria-label={intl.formatMessage({ id: "contact.ser" })}
                onChange={handleChange}
                forwardRef={register}
              />
            </Box>
            <Box width={[1, 1, 1 / 2]} px={themeFile.boxGutter} mb={3}>
              <InputText
                placeholder={`${intl.formatMessage({
                  id: "contact.purchase_date",
                })}:`}
                type="text"
                name="maintenance-target-purchase-date"
                aria-label={intl.formatMessage({ id: "contact.purchase_date" })}
                onChange={handleChange}
                forwardRef={register}
              />
            </Box>
            <Box width={[1, 1, 1 / 2]} px={themeFile.boxGutter} mb={3}>
              <InputText
                placeholder={`${intl.formatMessage({
                  id: "contact.reference_nr",
                })}:`}
                type="text"
                name="maintenance-target-reference-nr"
                aria-label={intl.formatMessage({ id: "contact.reference_nr" })}
                onChange={handleChange}
                forwardRef={register}
              />
            </Box>
            <Box width={[1]} px={themeFile.boxGutter} mb={2}>
              <Checkbox
                id="maintenance_target_warranty"
                name="maintenance-target-warranty"
                aria-label={intl.formatMessage({ id: "contact.warranty" })}
                // defaultChecked={state.maintenance_target_warranty}
                onChange={handleChange}
                text={intl.formatMessage({ id: "contact.warranty" })}
              />
            </Box>
          </Flex>
        </Fieldset>
        <Box mb={4}>
          <TextArea
            placeholder={`${intl.formatMessage({
              id: "contact.maintenance_description",
            })}*:`}
            name="message"
            aria-label={intl.formatMessage({
              id: "contact.maintenance_description",
            })}
            onChange={handleChange}
            forwardRef={register({ required: true })}
          />
          {errors.message && (
            <ErrorMessage
              message={intl.formatMessage({ id: "contact.error.message" })}
            />
          )}
        </Box>
        <Text textAlign="right">
          <FormButton type="submit">
            {intl.formatMessage({ id: "contact.send" })}
          </FormButton>
        </Text>
      </form>
    </div>
  )
}

MaintenanceForm.propTypes = propTypes

export default injectIntl(MaintenanceForm)
