import React, { useRef } from "react"
import { createPortal } from "react-dom"
import { string, func, node } from "prop-types"
import styled from "@emotion/styled"
import { useClearWithEsc } from "../../helpers"

const propTypes = {
  children: node,
  setModalOpen: func.isRequired,
  modalOpen: string.isRequired,
}

const defaultProps = {
  children: "",
}

const Portal = ({ children }) => {
  const modalRoot = document.getElementById("root-modal")

  return createPortal(<div>{children}</div>, modalRoot)
}

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px 10px;
  background-color: rgba(0, 0, 0, 0.4);
`

const ModalBody = styled.div`
  background-color: ${({ theme }) => theme.currentColours.bg};
  margin: auto;
  width: 100%;
  max-width: 720px;
  border-radius: 16px;
  padding: 14px;
  @media ${({ theme }) => theme.breakpoint.mobile} {
    padding: 40px;
  }
  @media ${({ theme }) => theme.breakpoint.desktop} {
    max-width: 860px;
  }
`

const CloseButton = styled.span`
  color: ${({ theme }) => theme.currentColours.fg};
  float: right;
  font-size: 28px;
  font-weight: bold;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.currentColours.fg};
    text-decoration: none;
    cursor: pointer;
  }
`

const Modal = ({ children, setModalOpen, modalOpen }) => {
  const nodeRef = useRef()
  // useOnClickOutside(nodeRef, () => setModalOpen(''))
  useClearWithEsc(() => setModalOpen(""))
  return (
    <Portal>
      {modalOpen && (
        <ModalWrapper>
          <ModalBody ref={nodeRef}>
            <CloseButton onClick={() => setModalOpen("")}>&times;</CloseButton>
            {children}
          </ModalBody>
        </ModalWrapper>
      )}
    </Portal>
  )
}

Modal.propTypes = propTypes

Modal.defaultProps = defaultProps

export default Modal
