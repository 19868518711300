import React from "react"
import { shape } from "prop-types"
import { Global, css } from "@emotion/core"
import fonts from "../fonts/index"
import resetCSS from "./reset"
// import theme from "../config/theme"

const propTypes = {
  theme: shape().isRequired,
}

const GlobalStyle = ({ theme }) => (
  <Global
    styles={css`
      ${resetCSS}

      @font-face {
        font-family: "Adieu";
        src: url(${fonts.AdieuLightWoff});
        src: url(${fonts.AdieuLightWoff}) format("woff"),
          url(${fonts.AdieuLightOtf}) format("truetype");
        font-weight: 300;
      }
      @font-face {
        font-family: "Adieu";
        src: url(${fonts.AdieuRegularWoff});
        src: url(${fonts.AdieuRegularWoff}) format("woff"),
          url(${fonts.AdieuRegularOtf}) format("truetype");
        font-style: normal;
        font-weight: 500;
      }
      @font-face {
        font-family: "Adieu";
        src: url(${fonts.AdieuBoldWoff});
        src: url(${fonts.AdieuBoldWoff}) format("woff"),
          url(${fonts.AdieuBoldOtf}) format("truetype");
        font-weight: 600;
      }
      @font-face {
        font-family: "Adieu";
        src: url(${fonts.AdieuBlackWoff});
        src: url(${fonts.AdieuBlackWoff}) format("woff"),
          url(${fonts.AdieuBlackOtf}) format("truetype");
        font-style: bold;
        font-weight: 700;
      }
      @font-face {
        font-family: "Thoreau";
        src: url(${fonts.ThoreauEot});
        src: url(${fonts.ThoreauEot}#iefix) format("embedded-opentype"),
          url(${fonts.ThoreauWoff2}) format("woff2"),
          url(${fonts.ThoreauWoff}) format("woff");
        font-style: normal;
      }
      html {
        font-size: 16px;
        overflow-x: hidden;
      }
      body {
        font-family: ${theme.typography.standardFamily};
        font-size: 3.9vw;
        font-weight: 300;
        background-color: ${theme.currentColours.bg};
        color: ${theme.currentColours.fg};
        /* padding-top: 50px; */
        padding-left: ${theme.gridGutter / 2}px;
        padding-right: ${theme.gridGutter / 2}px;
        @media ${theme.breakpoint.navCollapse} {
          /* padding-top: 8vw; */
          padding-bottom: 5vw;
        }
        @media ${theme.breakpoint.tablet} {
          padding-left: ${theme.gridGutter}px;
          padding-right: ${theme.gridGutter}px;
        }
        @media ${theme.breakpoint.laptop} {
          padding-left: ${theme.siteBorder}px;
          padding-right: ${theme.siteBorder}px;
        }
        @media ${theme.breakpoint.desktop} {
          padding-left: ${theme.gridGutter * 2}px;
          padding-right: ${theme.gridGutter * 2}px;
        }
        font-size: 3.9vw;
        @media ${theme.breakpoint.mobile} {
          font-size: 2.2vw;
        }
        @media ${theme.breakpoint.tablet} {
          font-size: 1.125vw;
        }
        @media ${theme.breakpoint.desktop} {
          font-size: 1.0125vw;
        }
      }
      p {
        font-size: 0.875rem;
        line-height: 1.2142857142857142em;
        letter-spacing: 0.1em;
      }
      ol {
        list-style-type: decimal;
        list-style-position: outside;
        li::marker {
          font-variant-numeric: normal;
        }
      }
    `}
  />
)

GlobalStyle.propTypes = propTypes

export default GlobalStyle
