/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { any, func, oneOfType, shape } from "prop-types"
import styled from "@emotion/styled"
import { Input } from "@rebass/forms"

const propTypes = {
  forwardRef: oneOfType([func, shape({ current: any })]),
}

const StyledInput = styled(Input)`
  font-size: 1rem;
  border: none !important;
  border-bottom: 1px solid ${({ theme }) => theme.currentColours.fg}!important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  &::placeholder {
    color: ${({ theme }) => theme.currentColours.fg}!important;
    opacity: 1;
    text-transform: uppercase;
  }
  &:focus::placeholder {
    color: transparent !important;
  }
`

const InputText = ({ forwardRef, ...props }) => (
  <StyledInput ref={forwardRef} {...props} />
)

const defaultProps = {
  forwardRef: () => {},
}

InputText.propTypes = propTypes
InputText.defaultProps = defaultProps

export default InputText
