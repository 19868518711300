import { useEffect, useState, useCallback } from "react"

// Window dimensions
export const useWindowDimensions = () => {
  const hasWindow = typeof window !== "undefined"

  const getWindowDimensions = () => {
    const width = hasWindow ? window.innerWidth : null
    const height = hasWindow ? window.innerHeight : null
    return {
      width,
      height,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions())
      }

      window.addEventListener("resize", handleResize)
      return () => window.removeEventListener("resize", handleResize)
    }
  }, [hasWindow])
  return windowDimensions
}

// Truncates texts
// eslint-disable-next-line import/prefer-default-export
export const Truncate = (text, n) =>
  text.length > n ? `${text.substr(0, n - 1)} ...` : text

// Clears open menu etc when clicking outside of the component
export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }
    document.addEventListener("mousedown", listener)
    return () => {
      document.removeEventListener("mousedown", listener)
    }
  }, [ref, handler])
}

// Clears open menu etc with Esc
export const useClearWithEsc = handler => {
  useEffect(() => {
    const listener = event => {
      if (event.keyCode !== 27) {
        return
      }
      handler()
    }
    document.addEventListener("keyup", listener)
    return () => {
      document.removeEventListener("keyup", listener)
    }
  }, [handler])
}

export const useToggle = (initialValue = false) => {
  const [toggle, setToggle] = useState(initialValue)

  return [toggle, useCallback(() => setToggle(status => !status), [])]
}

/**
 *
 * @param {Array} products
 * @param {String} id
 *
 * Used for getting the Finnish (default language) version of the image
 */
export const getFallbackImageFluid = (products, id) => {
  const match = products.find(
    ({ node: p }) => p.contentful_id === id && p.node_locale === "fi" && p.image
  )
  if (match && match.node.image.fluid) {
    return match.node.image.fluid
  }
  return null
}
