/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { arrayOf, func, string } from "prop-types"
import styled from "@emotion/styled"
import { Select } from "@rebass/forms"

const propTypes = {
  forwardRef: func,
  options: arrayOf(string.isRequired).isRequired,
  defaultOption: string.isRequired,
}

const StyledSelect = styled(Select)`
  font-size: 1rem;
  border: none !important;
  border-bottom: 1px solid ${({ theme }) => theme.currentColours.fg}!important;
  padding-left: 0px !important;
  padding-right: 0px !important;
`

const InputSelect = ({ forwardRef, options, defaultOption, ...props }) => (
  <StyledSelect ref={forwardRef} {...props}>
    <option value="">{defaultOption}</option>
    {options.map((value) => (
      <option key={value} value={value}>
        {value}
      </option>
    ))}
  </StyledSelect>
)

const defaultProps = {
  forwardRef: () => {},
}

InputSelect.propTypes = propTypes
InputSelect.defaultProps = defaultProps

export default InputSelect
