import React, { useContext } from "react"
import { string, node } from "prop-types"
import { Link } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import ModalContext from "./modal-context"

const propTypes = {
  slug: string.isRequired,
  active: string.isRequired,
  children: node.isRequired,
  type: string,
}

const defaultProps = {
  type: null,
}

const MenuLink = styled(Link)`
  font-family: ${({ theme }) => theme.typography.standardFamily};
  font-weight: ${({ active }) => (active === "true" ? "600" : "300")};
  font-size: 1.25rem;
  line-height: 1.0625rem;
  display: inline-block;
  padding: 0.826em 0;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ theme }) => theme.colours.black};
  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    color: ${({ theme }) => theme.currentColours.fg};
    padding: ${({ type }) =>
      type === "footer" ? "1.252vw 0 1.252vw" : "1.252vw 0 0.826em"};
    font-size: 100%;
  }
`
const MenuButton = styled.button`
  font-family: ${({ theme }) => theme.typography.standardFamily};
  font-weight: ${({ active }) => (active === "true" ? "600" : "300")};
  font-size: 1.25rem;
  line-height: 1.0625rem;
  display: inline-block;
  padding: 0.826em 0;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ theme }) => theme.colours.black};
  border: none;
  white-space: nowrap;
  -webkit-appearance: none;
  background: transparent;
  cursor: pointer;
  &:-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    color: ${({ theme }) => theme.currentColours.fg};
    font-size: 0.875rem;
  }
`

const MainMenuLink = ({ slug, active, children, type }) => {
  const setModalOpen = useContext(ModalContext)
  if (slug.includes("#contact")) {
    return (
      <MenuButton active={active} onClick={() => setModalOpen(slug)}>
        {children}
      </MenuButton>
    )
  }
  if (slug.includes("#maintenance")) {
    return (
      <MenuButton active={active} onClick={() => setModalOpen(slug)}>
        {children}
      </MenuButton>
    )
  }
  if (slug.includes("mailto:") || slug.includes("tel:")) {
    return (
      <MenuButton active={active} as="a" href={slug}>
        {children}
      </MenuButton>
    )
  }
  return (
    <MenuLink type={type} active={active} to={slug}>
      {children}
    </MenuLink>
  )
}

MainMenuLink.propTypes = propTypes
MainMenuLink.defaultProps = defaultProps

export default MainMenuLink
