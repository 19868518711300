// Breakpoints.
const size = {
  mobile: "480px",
  tablet: "768px",
  laptop: "1024px",
  wideLaptop: "1280px",
  desktop: "1440px",
  wideDesktop: "1920px",
}
const colours = {
  white: "#ffffff",
  black: "#000000",
  red: "#cc2446",
}
const space = [0, 4, 8, 15, 30, 45, 54, 128, 256]
const theme = {
  currentColours: {
    bg: "#FFFFFF",
    fg: "#7C633A",
  },
  colourTheme: {
    sinbad: {
      bg: "#AEDAD8",
      fg: "#7C633A",
    },
    sunglow: {
      bg: "#FFC140",
      fg: "#25754D",
    },
    white: {
      bg: "#FFFFFF",
      fg: "#7C633A",
    },
    mercury: {
      bg: "#E5E5E5",
      fg: "#000000",
    },
    yellowmetal: {
      bg: "#7C633A",
      fg: "#FFC13F",
    },
    seagreen: {
      bg: "#26764E",
      fg: "#FFC13F",
    },
  },
  colours: {
    white: colours.white,
    black: colours.black,
    red: colours.red,
  },
  breakpoints: [
    size.mobile,
    size.tablet,
    size.laptop,
    size.wideLaptop,
    size.desktop,
    size.wideDesktop,
  ],
  breakpoint: {
    mobile: `(min-width: ${size.mobile})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    wideLaptop: `(min-width: ${size.wideLaptop})`,
    desktop: `(min-width: ${size.desktop})`,
    wideDesktop: `(min-width: ${size.wideDesktop})`,
    navCollapse: `(min-width: ${size.wideLaptop})`,
  },
  space,
  gridGutter: space[4],
  flexGutter: [
    -space[4] / 2,
    -space[4] / 2,
    -space[4] / 2,
    -space[4] / 1.5,
    -space[4],
  ],
  boxGutter: [
    space[4] / 2,
    space[4] / 2,
    space[4] / 2,
    space[4] / 1.5,
    space[4],
  ],
  boxBottomMargin: [2, 4, 3],
  siteBorder: space[5],
  typography: {
    standoutFamily: `"Thoreau", "Georgia", serif`,
    standoutWeight: "normal",
    standardFamily: `"Adieu", "Verdana", sans-serif`,
  },
}

export const getColoursByTheme = colourTheme => {
  return theme.colourTheme[colourTheme]
}

export default theme
