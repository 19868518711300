import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import { shape, string } from "prop-types"
import styled from "@emotion/styled"

const propTypes = {
  slugs: shape({
    en: string,
    fi: string,
  }).isRequired,
}

const languageName = {
  en: "En",
  fi: "Fi",
}

const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  width: auto;
  cursor: pointer;
  font-family: ${({ theme }) => theme.typography.standardFamily};
  font-weight: ${props => (props.active === "true" ? "600" : "300")};
  font-size: 0.875rem;
  line-height: 1.0625rem;
  display: inline-block;
  padding: 0.826em 0;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ theme }) => theme.colours.black};

  -webkit-appearance: none;
  &:-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &:first-of-type {
    &:after {
      content: "/";
    }
  }
  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    color: ${({ theme }) => theme.currentColours.fg};
  }
`

const getTranslation = (lang, slugs) => {
  if (Object.prototype.hasOwnProperty.call(slugs, lang) && slugs[lang]) {
    return `/${slugs[lang]}`
  }
  return `/`
}

const Language = ({ slugs }) => (
  <div>
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) =>
        languages.map(language => (
          <StyledButton
            key={language}
            type="button"
            onClick={() =>
              changeLocale(language, getTranslation(language, slugs))
            }
            active={currentLocale === language ? "true" : "false"}
          >
            {languageName[language]}
          </StyledButton>
        ))
      }
    </IntlContextConsumer>
  </div>
)

Language.propTypes = propTypes

export default Language
