import React from "react"
import { shape, string, func } from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import MenuItem from "./menu-item"

const propTypes = {
  intl: shape().isRequired,
  currentPath: string.isRequired,
  forwardRef: shape().isRequired,
  open: string.isRequired,
  setOpen: func.isRequired,
}

const BrandsMenu = ({ intl, currentPath, forwardRef, open, setOpen }) => {
  return (
    <StaticQuery
      query={graphql`
        query allContentfulBrand {
          allContentfulBrand(sort: { order: ASC, fields: title }) {
            edges {
              node {
                id
                title
                node_locale
                slug
              }
            }
          }
        }
      `}
      render={data => {
        const { edges: brands } = data.allContentfulBrand
        const { locale } = intl
        const item = {
          title: intl.formatMessage({ id: "brand" }),
          slug: intl.formatMessage({ id: "brand_slug" }),
          subCategory: brands
            .filter(i => i.node.node_locale === locale)
            .map(b => ({ title: b.node.title, slug: b.node.slug })),
        }
        return (
          <MenuItem
            forwardRef={forwardRef}
            item={item}
            open={open}
            setOpen={setOpen}
            currentPath={currentPath}
          />
        )
      }}
    />
  )
}

BrandsMenu.propTypes = propTypes

export default injectIntl(BrandsMenu)
