import React from "react"
import styled from "@emotion/styled"
import { rgba } from "polished"
import { string } from "prop-types"

const propTypes = {
  message: string,
}

const StyledErrorMessage = styled.div`
  color: ${({ theme }) => rgba(theme.currentColours.fg, 0.6)};
  font-size: 0.8rem;
  padding: 0.2em 0;
`

const ErrorMessage = ({ message }) => {
  return <StyledErrorMessage>{message}</StyledErrorMessage>
}

const defaultProps = {
  message: "",
}

ErrorMessage.propTypes = propTypes
ErrorMessage.defaultProps = defaultProps

export default ErrorMessage
