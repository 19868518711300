/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import styled from "@emotion/styled"
import { string } from "prop-types"

const propTypes = {
  children: string.isRequired,
}

const Submit = styled.button`
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.currentColours.fg};
  color: ${({ theme }) => theme.currentColours.fg};
  background-color: transparent;
  border-radius: 500px;
  padding: 0.55vw 1.2vw 0.6vw 1.2vw;
  line-height: 1.1818181818181819em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  margin-bottom: 1.5vw;
  cursor: pointer;
`

const FormButton = ({ children, ...props }) => (
  <Submit {...props}>{children}</Submit>
)

FormButton.propTypes = propTypes

export default FormButton
