import React from "react"
import { bool, shape } from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"
import { navigate } from "gatsby-link"
import { getDay, isBefore } from "date-fns"
import { useForm, Controller } from "react-hook-form"
import styled from "@emotion/styled"
import { Text, Box } from "rebass"
import Heading2 from "../atoms/heading-2"
import InputText from "../atoms/input-text"
import TextArea from "../atoms/text-area"
import FormButton from "../atoms/form-button"
import Fieldset from "../atoms/fieldset"
import Legend from "../atoms/legend"
import InputSelect from "../atoms/input-select"
import Checkbox from "../atoms/checkbox"
import ErrorMessage from "../atoms/form-error-message"
import DatePicker from "../atoms/datepicker"

const propTypes = {
  intl: shape().isRequired,
  isRemoteSelected: bool,
}

const ContactFormTitle = styled(Heading2)``

const modifiers = {
  disabled: (date) => {
    const day = getDay(date)
    if (isBefore(date, new Date())) {
      return true
    }
    if (day === 6 || day === 0) {
      return true
    }
    return false
  },
}

const encode = (data) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&")
}

const ContactForm = ({ intl, isRemoteSelected }) => {
  const { locale } = intl

  const { register, handleSubmit, control, errors, watch } = useForm({
    defaultValues: {
      remote_meeting: isRemoteSelected,
      language: locale,
    },
  })

  const checkboxState = watch("remote_meeting")

  const onSubmit = (data, e) => {
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...data,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error))
  }

  return (
    <div>
      <ContactFormTitle>
        {intl.formatMessage({ id: "contact.contact_us" })}
      </ContactFormTitle>
      <form
        name="contact_form"
        method="post"
        action={`/${locale}/thanks`}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact_form" />
        <input type="hidden" name="language" readOnly />
        <p hidden>
          <input name="bot-field" />
        </p>
        <Box mb={4}>
          <Text>{intl.formatMessage({ id: "contact.intro" })}</Text>
        </Box>
        <Box mb={4} width={[1]}>
          <Checkbox
            id="remote_meeting"
            name="remote_meeting"
            aria-label={intl.formatMessage({ id: "contact.book_a_meeting" })}
            defaultChecked={checkboxState}
            text={intl.formatMessage({ id: "contact.book_a_meeting" })}
            forwardRef={register}
          />
        </Box>
        {checkboxState && (
          <Fieldset>
            <Legend>{`${intl.formatMessage({
              id: "contact.date_and_time",
            })}`}</Legend>
            <Box mb={4}>
              <Controller
                control={control}
                name="date"
                defaultValue={null}
                rules={{ required: true }}
                render={({ onChange, value, ref }) => (
                  <DatePicker
                    forwardRef={ref}
                    handleDate={onChange}
                    date={value}
                    modifiers={modifiers}
                    locale={locale}
                    placeholder={intl.formatMessage({ id: "contact.date" })}
                  />
                )}
              />
              {errors.date && (
                <ErrorMessage
                  message={intl.formatMessage({ id: "contact.error.date" })}
                />
              )}
            </Box>
            <Box mb={4}>
              <InputSelect
                defaultOption={intl.formatMessage({
                  id: "contact.select_time",
                })}
                name="time"
                options={["9-13", "13-17"]}
                forwardRef={register({ required: true })}
              />
              {errors.time && (
                <ErrorMessage
                  message={intl.formatMessage({ id: "contact.error.time" })}
                />
              )}
            </Box>
          </Fieldset>
        )}
        <Box mb={4}>
          <InputText
            placeholder={`${intl.formatMessage({ id: "contact.name" })}*:`}
            type="text"
            name="name"
            aria-label={intl.formatMessage({ id: "contact.name" })}
            forwardRef={register({ required: true })}
          />
          {errors.name && (
            <ErrorMessage
              message={intl.formatMessage({ id: "contact.error.name" })}
            />
          )}
        </Box>
        <Box mb={4}>
          <InputText
            placeholder={`${intl.formatMessage({ id: "contact.company" })}*:`}
            type="text"
            name="company"
            aria-label={intl.formatMessage({ id: "contact.company" })}
            forwardRef={register({ required: true })}
          />
          {errors.company && (
            <ErrorMessage
              message={intl.formatMessage({ id: "contact.error.company" })}
            />
          )}
        </Box>
        <Box mb={4}>
          <InputText
            placeholder={`${intl.formatMessage({ id: "contact.phone" })}*:`}
            type="text"
            name="phone"
            aria-label={intl.formatMessage({ id: "contact.phone" })}
            forwardRef={register({ required: true })}
          />
          {errors.phone && (
            <ErrorMessage
              message={intl.formatMessage({ id: "contact.error.phone" })}
            />
          )}
        </Box>
        <Box mb={5}>
          <InputText
            placeholder={`${intl.formatMessage({ id: "contact.email" })}*:`}
            type="email"
            name="email"
            aria-label={intl.formatMessage({ id: "contact.email" })}
            forwardRef={register({ required: true })}
          />
          {errors.email && (
            <ErrorMessage
              message={intl.formatMessage({ id: "contact.error.email" })}
            />
          )}
        </Box>
        <Box mb={4}>
          <TextArea
            placeholder={`${intl.formatMessage({ id: "contact.message" })}*:`}
            name="message"
            aria-label={intl.formatMessage({ id: "contact.message" })}
            forwardRef={register({ required: true })}
          />
          {errors.message && (
            <ErrorMessage
              message={intl.formatMessage({ id: "contact.error.message" })}
            />
          )}
        </Box>
        <Text textAlign="right">
          <FormButton type="submit">
            {intl.formatMessage({ id: "contact.send" })}
          </FormButton>
        </Text>
      </form>
    </div>
  )
}

const defaultProps = {
  isRemoteSelected: false,
}

ContactForm.defaultProps = defaultProps
ContactForm.propTypes = propTypes

export default injectIntl(ContactForm)
