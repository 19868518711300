/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import styled from "@emotion/styled"
import { rgba } from "polished"

const StyledFieldset = styled.fieldset`
  border-color: ${({ theme }) => rgba(theme.currentColours.fg, 0.5)};
  padding: 10px;
`

const Fieldset = ({ children, ...props }) => (
  <StyledFieldset {...props}>{children}</StyledFieldset>
)

export default Fieldset
