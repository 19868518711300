/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { string, arrayOf, object, shape } from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

const propTypes = {
  intl: shape().isRequired,
  image: string,
  lang: string,
  meta: arrayOf(object),
  title: string.isRequired,
  location: string.isRequired,
}

const defaultProps = {
  lang: `en`,
  meta: [],
  image: null,
}

function SEO({ lang, meta, title, image, intl, location }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            defaultImage: image
            siteUrl: url
          }
        }
      }
    `
  )

  const ogImage = `${site.siteMetadata.siteUrl}${image ||
    site.siteMetadata.defaultImage}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      script={[
        {
          innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WFPR96PN');`
        },
      ]}
      link={[
        {
          rel: "alternate",
          hreflang: lang,
          href: `${site.siteMetadata.siteUrl}${location}`,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: intl.formatMessage({ id: "description" }),
        },
        {
          property: `google-site-verification`,
          content: `A4rqcpF4hdzFLJ-oJMjHQeUl5o9lLQvzW7MmDxS_iqA`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: intl.formatMessage({ id: "description" }),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: intl.formatMessage({ id: "description" }),
        },
      ].concat(meta)}
    />
  )
}

SEO.propTypes = propTypes
SEO.defaultProps = defaultProps

export default injectIntl(SEO)
