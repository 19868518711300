import React from "react"
import styled from "@emotion/styled"
import { string, func } from "prop-types"

const propTypes = {
  active: string.isRequired,
  open: string,
  setOpen: func,
  children: string,
  className: string,
  slug: string.isRequired,
}

const defaultProps = {
  children: "",
  open: "",
  setOpen: () => {},
  className: "",
}

const StyledMenuLink = styled.button`
  font-family: ${({ theme }) => theme.typography.standardFamily};
  font-weight: ${({ active }) => (active === "true" ? "600" : "300")};
  font-size: 0.675rem;
  display: block;
  line-height: 1.0625rem;
  padding: 0.826em 0;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  border: 0;
  background-color: transparent;
  text-align: left;
  color: ${({ theme }) => theme.colours.black};
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:focus {
    outline: 0;
  }
  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    color: ${({ theme }) => theme.currentColours.fg};
  }
`

const MenuCategoryLink = ({
  active,
  open,
  setOpen,
  children,
  className,
  slug,
}) => (
  <StyledMenuLink
    className={className}
    active={active}
    onClick={() => {
      if (slug === open) {
        return setOpen("")
      }
      return setOpen(slug)
    }}
  >
    {children}
  </StyledMenuLink>
)

MenuCategoryLink.propTypes = propTypes

MenuCategoryLink.defaultProps = defaultProps

export default MenuCategoryLink
