/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { func, string } from "prop-types"
import { fi, enGB } from "date-fns/locale"
import { DatePicker as DatePickerCalendar } from "react-nice-dates"
import styled from "@emotion/styled"
import { rgba } from "polished"
import InputText from "./input-text"
import "react-nice-dates/build/style.css"

const propTypes = {
  handleDate: func.isRequired,
  placeholder: string,
  locale: string.isRequired,
}

const DatePickerContainer = styled.div`
  .nice-dates-popover {
    transform: translateX(-50%);
    left: 50%;
    background-color: ${({ theme }) => theme.currentColours.bg};
  }
  .nice-dates-week-header_day,
  .nice-dates-day_month,
  .nice-dates-day.-outside {
    color: ${({ theme }) => rgba(theme.currentColours.fg, 0.5)};
  }
  .nice-dates-week-header_day {
    color: ${({ theme }) => theme.currentColours.fg};
  }
  .nice-dates-day:before {
    background-color: ${({ theme }) => theme.currentColours.fg};
  }
  .nice-dates-navigation_previous:before,
  .nice-dates-navigation_next:before {
    border-color: ${({ theme }) => theme.currentColours.fg};
  }
  .nice-dates-day.-disabled {
    color: rgba(0, 0, 0, 0.2);
  }
`

const StyledInputText = styled(InputText)`
  &::placeholder {
    text-transform: none;
  }
`

const DatePicker = ({ handleDate, placeholder, locale, ...rest }) => {
  return (
    <DatePickerContainer>
      <DatePickerCalendar
        {...rest}
        locale={locale === "fi" ? fi : enGB}
        onDateChange={handleDate}
      >
        {({ inputProps, focused }) => {
          const {
            onBlur,
            onFocus,
            onChange,
            readonly,
            type,
            ref,
            value,
          } = inputProps
          return (
            <StyledInputText
              className={`input${focused ? " -focused" : ""}`}
              placeholder={placeholder}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              readonly={readonly}
              type={type}
              value={value}
              forwardRef={ref}
            />
          )
        }}
      </DatePickerCalendar>
    </DatePickerContainer>
  )
}

const defaultProps = {
  placeholder: "",
}

DatePicker.propTypes = propTypes
DatePicker.defaultProps = defaultProps

export default injectIntl(DatePicker)
