import React, { useState, useRef } from "react"
import { shape, string } from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import { Flex } from "rebass"
import MenuItem from "./molecules/menu-item"
import BrandsMenu from "./molecules/brands-menu"
import { useOnClickOutside } from "../helpers"

const propTypes = {
  intl: shape().isRequired,
  currentPath: string.isRequired,
  className: string,
  type: string,
}

const defaultProps = {
  className: "",
  type: "",
}

const StyledFlex = styled(Flex)`
  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    /* background-color: ${({ theme }) => theme.currentColours.bg}; */
    padding-top: 1.4vw;
    padding-bottom: 18px;
    /* position: absolute; */
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    height: auto;
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
  }
`

const ProductsMenu = ({ intl, currentPath, className, type }) => {
  const { locale } = intl

  const [open, setOpen] = useState("")
  const node = useRef()
  useOnClickOutside(node, () => {
    if (type === "mobile") {
      return
    }
    setOpen("")
  })

  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulNavigation(
            filter: { slug: { eq: "product-navigation" } }
          ) {
            edges {
              node {
                id
                node_locale
                item {
                  ... on ContentfulCategory {
                    displayName
                    slug
                    node_locale
                    title
                    subCategory {
                      __typename
                      ... on Node {
                        ... on ContentfulSubCategory {
                          title
                          slug
                          product {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { edges } = data.allContentfulNavigation
        const categories = edges.filter(i => i.node.node_locale === locale)[0]
          .node.item
        // console.log(categories);
        return (
          <div className={className}>
            <StyledFlex flexWrap="wrap">
              {categories &&
                categories.map(item => (
                  <MenuItem
                    forwardRef={node}
                    type={type}
                    key={item.slug}
                    currentPath={currentPath}
                    item={item}
                    open={open}
                    setOpen={setOpen}
                  />
                ))}
              <BrandsMenu
                forwardRef={node}
                currentPath={currentPath}
                open={open}
                setOpen={setOpen}
              />
            </StyledFlex>
          </div>
        )
      }}
    />
  )
}

ProductsMenu.propTypes = propTypes

ProductsMenu.defaultProps = defaultProps

export default injectIntl(ProductsMenu)
