import React, { useState, useRef } from "react"
import { StaticQuery, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { shape, string } from "prop-types"
import Burger from "./atoms/burger"
import Menu from "./molecules/menu"
import { useOnClickOutside, useClearWithEsc } from "../helpers"

const propTypes = {
  intl: shape().isRequired,
  slugs: shape({
    en: string,
    fi: string,
  }).isRequired,
  currentPath: string.isRequired,
  theme: shape().isRequired,
}

const Header = ({ intl, slugs, currentPath, theme }) => {
  const { locale } = intl
  const [open, setOpen] = useState(false)
  const node = useRef()
  const menuId = "main-menu"
  useOnClickOutside(node, () => setOpen(false))
  useClearWithEsc(() => setOpen(false))
  return (
    <StaticQuery
      query={graphql`
        query contentfulNavigation {
          mainNavigation: allContentfulNavigation(
            filter: { slug: { eq: "main-navigation" } }
          ) {
            ...Navigation
          }
          footerNavigation: allContentfulNavigation(
            filter: { slug: { eq: "footer-navigation" } }
          ) {
            ...Navigation
          }
          subNavigation: allContentfulNavigation(
            filter: { slug: { eq: "sub-navigation" } }
          ) {
            ...Navigation
          }
        }
      `}
      render={data => {
        const { edges: edgesMain } = data.mainNavigation
        const currentMainNav = edgesMain.filter(
          i => i.node.node_locale === locale
        )[0]
        const { edges: edgesFooter } = data.footerNavigation
        const currentFooterNav = edgesFooter.filter(
          i => i.node.node_locale === locale
        )[0]
        const mainNavItems = currentMainNav.node.item
        const footerNavItems = currentFooterNav.node.item
        return (
          <div ref={node}>
            <Menu
              theme={theme}
              slugs={slugs}
              currentPath={currentPath}
              mainNavItems={mainNavItems}
              footerNavItems={footerNavItems}
              open={open}
              id={menuId}
            />
            <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
          </div>
        )
      }}
    />
  )
}

Header.propTypes = propTypes

export default injectIntl(Header)
